import * as React from 'react';
import { connect } from 'react-redux';
import Router, { SingletonRouter } from 'next/router';

import { userInfoSeletor } from '@src/selectors/userSeletor';
import { IAppState } from '@src/interfaces/IAppState';
import { getPathHasLastSlash } from '@src/utils/functionUtils';

import { pathConstant } from '@src/utils/appContanst';
import { IUserInfo } from '@src/interfaces/IUserState';
import { withRouterParsedQuery } from './withRouterParsedQuery';

interface Props {
  user: IUserInfo;
  router: SingletonRouter;
  query: {[key: string]: string};
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export const withLinkAccount = (ComposedComp: any) => {
  class withLinkAccountComponent extends React.Component<Props> {
    constructor(props: Props) {
      super(props);
      this.gotoTermOfUseLink = this.gotoTermOfUseLink.bind(this);
      this.handleBack = this.handleBack.bind(this);
    }

    gotoTermOfUseLink() {
      const { query } = this.props;

      Router.push({
        pathname: `${getPathHasLastSlash(pathConstant.topPath)}term_of_use/`,
        query: {
          ...query,
          is_black_route: true
        }
      });
    }

    handleBack = () => {
      let backLink = pathConstant.topPath;
      if (typeof window !== 'undefined' && sessionStorage) {
        // because /term_of_use & /tutorial didn't store in historyRoutes
        const historyRoutes = JSON.parse(sessionStorage.getItem('historyRoutes') || '[]');
        backLink = historyRoutes.length ? historyRoutes.pop() : backLink;
      }

      return backLink;
    }

    handleCloseModalTutorial = () => {
      const { router, query } = this.props;

      query && delete query.is_black_route;
      query && delete query.redirect_path;
      Router.push({
        pathname: router.pathname,
        query
      });
    }

    render() {
      return <ComposedComp
        {...this.props}
        gotoTermOfUseLink={this.gotoTermOfUseLink}
        goBackLink={this.handleBack()}
        handleCloseModalTutorial={this.handleCloseModalTutorial}
      />;
    }
  }

  const mapStateToProps = (state: IAppState) => ({
    user: userInfoSeletor(state)
  });

  const mapDispatchToProps = () => ({});

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return connect(mapStateToProps, mapDispatchToProps)(withRouterParsedQuery(withLinkAccountComponent));
};
