import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import smoothscroll from 'smoothscroll-polyfill';
import { show } from 'redux-modal';
import queryString from 'query-string';
import Sentry from '@src/utils/sentry';

import { withAppAuthenticating } from '@src/hocs/withModalAuthentication';
import { initGA } from '@src/utils/analytics';
import FooterNav from './FooterNav';
import { loadUserAssetInfo } from '@src/actions/userActions';
import { IAppState } from '@src/interfaces/IAppState';
import { userInfoSeletor } from '@src/selectors/userSeletor';
import { IUserInfo } from '@src/interfaces/IUserState';
import { isLoadingSeletor, skipScrollTopSelector } from '@src/selectors/commonSelector';
import { updateSkipScrollTop } from '@src/actions/commonActions';
import { withRouterParsedQuery } from '@src/hocs/withRouterParsedQuery';
import Router, { SingletonRouter } from 'next/router';
import { MODAL_NAME, pathConstant } from '@src/utils/appContanst';
import ModalTutorial from './ModalTutorial';
import { Portal } from './Portal';
import { getPathHasLastSlash, handleScrollTop, isRightAssetDetailUrl, isRightSDKUrl } from '@src/utils/functionUtils';
import ErrorPage from '../../pages/_error';
import { ERROR_RESPONSE_TYPES } from '@src/utils/errorHandler';
import { handleBackInReportSetting } from '@src/actions/reportActions';
import { ModalProgressBar } from './ModalProgressBar';
import MenuList from './MenuList';
import BackButton from './BackButton';
import { getBundleInfo } from '@src/actions/balanceActions';
import { IBundleResponse } from '@src/interfaces/IBundle';
import ModalDeletedStockUserUnused from '@src/components/ModalDeletedStockUserUnused';

interface LayoutProps {
  query: { [key: string]: string };
  router: SingletonRouter;
  user: IUserInfo;
  isOpenLoading?: boolean;
  skipScrollTop?: boolean;
  hidePullRefresh?: boolean;
  disableAccListLink?: boolean;
  withButtonBottomPage?: boolean;
  isLifeStyle?: boolean;
  fixedFooter?: boolean;
  loadUserAssetInfo(): void;
  updateSkipScrollTop(skipScrollTop: boolean): void;
  showModalTutorial(): void;
  isEditing: boolean;
  showModalConfirmCancelEdit: () => void;
  handleBackInReportSetting: () => void;
  displayFooterNav: boolean;
  notDisplayBackButton: boolean;
  bundleSettings: IBundleResponse;
  getBundle(): void
}

interface LayoutState {
  isOpenMenu: boolean;
  isRunOpenTutorial: boolean;
}

class Layout extends React.Component<LayoutProps, LayoutState> {
  constructor(props: LayoutProps) {
    super(props);

    this.state = {
      isOpenMenu: false,
      isRunOpenTutorial: false
    };
  }

  componentDidMount() {
    const { asPath } = this.props.router;
    this.handleRouteChangeComplete(asPath);
    Router.events.on('routeChangeComplete', this.handleRouteChangeComplete);
    window.addEventListener('popstate', this.handleBackBrowser);

    if (typeof window !== 'undefined' && sessionStorage.getItem('token')) {
      smoothscroll.polyfill();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const windowClient = window as any;
      if (!windowClient.GA_INITIALIZED) {
        initGA();
        windowClient.GA_INITIALIZED = true;
      }
      this.props.loadUserAssetInfo();
      this.props.getBundle();
    }
  }

  componentWillUnmount() {
    Router.events.off('routeChangeComplete', this.handleRouteChangeComplete);
  }

  handleBackBrowser = (event: any) => {
    if (!sessionStorage) return pathConstant.topPath;
    const historyRoutes = JSON.parse(sessionStorage.getItem('historyRoutes') || '[]');

    // need to run pop() 2 times because listening 'routeChangeComplete' before
    historyRoutes.length && historyRoutes.pop();
    historyRoutes.length && historyRoutes.pop();
    const prevRoute = historyRoutes.length ? historyRoutes[historyRoutes.length - 1] : pathConstant.topPath;
    const url = event.state && event.state.url ? event.state.url : '';

    if (url && event.state.url === prevRoute) {
      sessionStorage.setItem('historyRoutes', JSON.stringify(historyRoutes));
    }
  }

  handleRouteChangeComplete = (url: string) => {
    const { hisByCategoryPath, topPath } = pathConstant;
    const { router, query } = this.props;
    // incase page is au-wallet top, back btn will not be active
    if (sessionStorage) {
      if (url === topPath) {
        sessionStorage.setItem('historyRoutes', '[]');
      } else {
        const pathname = getPathHasLastSlash(router.pathname);
        const { is_black_route = false, status = '' } = query || {};
        const historyRoutes = JSON.parse(sessionStorage.getItem('historyRoutes') || '[]');
        const lastRouter = historyRoutes.length ? historyRoutes[historyRoutes.length - 1] : pathConstant.topPath;
        const { url: prevUrl } = queryString.parseUrl(lastRouter);

        // because historyByCategory is using time in query to get data
        if (pathname === hisByCategoryPath && getPathHasLastSlash(prevUrl) === hisByCategoryPath && url.indexOf('modal_category_status=open') < 0) {
          historyRoutes.pop();
          historyRoutes.push(url);
        } else if (lastRouter !== url && !is_black_route && status !== 'ok') {
          // incase url includes query `is_black_route`, back btn will work wrong, ex: tutorial/?is_black_route=true or term_of_use/?is_black_route=true
          // or incase link account success, back btn will work wrong too
          historyRoutes.push(url);
        }
        sessionStorage.setItem('historyRoutes', JSON.stringify(historyRoutes));
      }
    }
    // this.openTuTorial();
  }

  componentDidUpdate(prevProp: LayoutProps) {
    const { isOpenLoading, skipScrollTop, updateSkipScrollTop } = this.props;

    if (!prevProp.isOpenLoading && isOpenLoading && document) {
      if (!skipScrollTop) {
        document.documentElement && (document.documentElement.scrollTop = 0);
        document.body && (document.body.scrollTop = 0);
        handleScrollTop();
      } else {
        updateSkipScrollTop(false);
      }
    }
    // if (!prevState.isRunOpenTutorial) {
    //   this.openTuTorial();
    // }
  }

  // openTuTorial = () => {
  //   if (localStorage) {
  //     const tutorialRead = localStorage.getItem('TUTORIAL_READ');
  //     if (tutorialRead !== 'true') {
  //       this.props.showModalTutorial();
  //     }
  //     this.setState({
  //       isRunOpenTutorial: true
  //     });
  //   }
  // }

  handleWrongUrl() {
    const { router, query } = this.props;
    const { pathname } = router;
    const sentryError = {
      title: 'No support SDK - wrong asset detail url',
      message: `url: ${pathname}`,
      statusCode: ''
    };

    Sentry().captureException(new Error('No support SDK'), { errorInfo: sentryError, pathname, query });
    return <ErrorPage
      statusCode={404}
      errorResponseType={ERROR_RESPONSE_TYPES.OTHERS_TYPES}
      sentryMsg={'No support SDK'}
      message={'無許可'} />;
  }

  handleDisplayBackButton = (notDisplayBackBtn: boolean) => {
    const { query } = this.props;
    const isTransactionCategory = (pathConstant.hisByCategoryPath && query.modal_category_status === 'open');
    const isColumnHasId = (query.id && pathConstant.columns);

    return !notDisplayBackBtn || isTransactionCategory || isColumnHasId;
  };

  handleDisplayMenuFooter = (isDisplayFooterNav: boolean) => {
    const { query } = this.props;
    const isHistoryTransactionCategory = (pathConstant.cateHistoryAll && query.modal_category_status === 'open');
    const isColumnHasId = (query.id && pathConstant.columns);

    return isDisplayFooterNav && !isHistoryTransactionCategory && !isColumnHasId;
  }

  toggleMenuList = (isOpen: boolean) => {
    this.setState({
      isOpenMenu: isOpen
    });
  };

  closeMenuList = () => this.toggleMenuList(false);

  render() {
    const {
      children,
      isOpenLoading,
      isLifeStyle,
      withButtonBottomPage,
      fixedFooter,
      user,
      router,
      query,
      notDisplayBackButton,
      displayFooterNav,
      bundleSettings
    } = this.props;
    const { show_financial_packages } = bundleSettings.admin_settings;
    const isCategoryListModal = query.modal_category_status === 'open';
    const fullPathname = getPathHasLastSlash(router.pathname);
    const isAssetDetailPage = fullPathname === pathConstant.assetDetailPath;
    const isDisplayBackBtn = this.handleDisplayBackButton(notDisplayBackButton);
    const isDisplayFooterNav = this.handleDisplayMenuFooter(displayFooterNav);
    const mainContainerStyle = isDisplayFooterNav ? 'pb-76' : 'pb-0';

    if (isAssetDetailPage && !isRightAssetDetailUrl(fullPathname, query) && !isRightSDKUrl(fullPathname, query)) {
      return this.handleWrongUrl();
    }

    return (
      <div className="next-container" id='next-container'>
        <div className={`${isDisplayFooterNav ? 'normal-wrapper' : 'navigator-bottom-wrapper'}`}
          id="navigator-top-wrapper">
          <div
            className={`${isLifeStyle ? 'scroll-life-style' : 'scroll-chan'} 
              ${withButtonBottomPage ? 'with-button-bottom-page' : ''} 
              ${fixedFooter ? 'with-fixed-footer' : ''}
              bg-gray
            `}
            id="scroll-chan"
            style={{ overflow: 'scroll' }}
          >
            <div className={`${mainContainerStyle} main-container`}>
              {children}
            </div>
            {isDisplayBackBtn && !isCategoryListModal && <BackButton />}
            <MenuList
              user={user}
              isOpenMenu={this.state.isOpenMenu}
              closeMenu={() => this.closeMenuList()}
              isShowBundle={!!show_financial_packages}
            />
          </div>
          {isDisplayFooterNav &&
            <FooterNav
              isOpenMenu={this.state.isOpenMenu}
              onToggleMenu={(isOpen: boolean) => this.toggleMenuList(isOpen)}
            />
          }
          {isOpenLoading && <ModalProgressBar />}
          <Portal selectorElement="#portal-modal">
            <ModalTutorial />
            <ModalDeletedStockUserUnused />
            {
              // display when category transaction open. because if back button in outside, in iOS back button will not display anymore
              isDisplayBackBtn && isCategoryListModal && <BackButton />
            }
          </Portal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  user: userInfoSeletor(state),
  isOpenLoading: isLoadingSeletor(state),
  skipScrollTop: skipScrollTopSelector(state),
  isEditing: state.commonState.isEditing,
  bundleSettings: state.balanceState.bundle
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  showModalTutorial: () => dispatch(show(MODAL_NAME.TUTORIAL)),
  loadUserAssetInfo: () => dispatch(loadUserAssetInfo()),
  updateSkipScrollTop: (skipScrollTop: boolean) => dispatch(updateSkipScrollTop(skipScrollTop)),
  showModalConfirmCancelEdit: () => dispatch(show('CONFIRM_CANCEL_EDIT_MODAL')),
  handleBackInReportSetting: () => dispatch(handleBackInReportSetting()),
  getBundle: () => dispatch(getBundleInfo())
});

export default withRouterParsedQuery(connect(mapStateToProps, mapDispatchToProps)(withAppAuthenticating(Layout)));
