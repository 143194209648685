import { IAppState } from '@src/interfaces/IAppState';

export const balanceStateSelector = (appState: IAppState) => (
  appState.balanceState
);

export const balanceAccountSelector = (appState: IAppState) => (
  appState.balanceState.balance_account
);

export const bankSelector = (appState: IAppState) => (
  appState.balanceState.bank
);

export const stockSelector = (appState: IAppState) => (
  appState.balanceState.stock
);

export const ecSelector = (appState: IAppState) => (
  appState.balanceState.ec
);
export const cardSelector = (appState: IAppState) => (
  appState.balanceState.credit
);

export const monthSelectSelector = (appState: IAppState) => (
  appState.balanceState.monthSelect
);

export const maintenanceStatusSelector = (appState: IAppState) => (
  appState.balanceState.maintenance_status
);

export const transactionsChartSelector = (appState: IAppState) => (
  appState.balanceState.transactionsChart
);

export const isAuLoadingSelector = (appState: IAppState) => (
  appState.balanceState.isAuLoading
);

export const isStockLoadingSelector = (appState: IAppState) => (
  appState.balanceState.isStockLoading
);

export const isInsuranceLoadingSelector = (appState: IAppState) => (
  appState.balanceState.isInsuranceLoading
);

export const insuranceSettingsSelector = (appState: IAppState) => (
  appState.balanceState.insuranceSettings
);
