import jwtDecode from 'jwt-decode';
import moment from '@src/utils/kpfmMoment';
import _find from 'lodash.find';
import _orderBy from 'lodash.orderby';
import _filter from 'lodash.filter';
import _reduce from 'lodash.reduce';
import _isEmpty from 'lodash.isempty';
import _isArray from 'lodash.isarray';
import _groupBy from 'lodash.groupby';
import _foreach from 'lodash.foreach';
import Sentry from '@src/utils/sentry';
import { IUserInfo } from '@src/interfaces/IUserState';
import {
  BUNDLE_CONTACT_MSG,
  BUNDLE_ELEMENT,
  BUNDLE_GA_MSG,
  BUNDLE_NOTICE_TEXT,
  FINANCIAL_BUNDLE,
  KANTAN_USER_TYPE,
  localStorageKeys,
  MAINTENANCE_STATUS,
  pathConstant,
  TRACKING_DATA
} from '@src/utils/appContanst';
import { IRecommend, IAccount, IBalanceState, IPointInfo, IKantanInfo, IKantanKiyaku, IUserCondition } from '@src/interfaces/IBalanceState';
import { AccountType, SERVICE_TYPES } from './appContanst';
import { IAccountItem } from '@src/interfaces/IAccountState';
import { ErrorCurrentAccounts, ErrorCurrentAccount } from '@src/interfaces/IErrorCurrentAccount';
import { formatDate } from './dateTime';
import getConfig from 'next/config';
import { initialMessageSetting, initialPointInfo, initialUserCondition, intitialKantanInfo } from './initialData';
import { ICalendarMonthValue } from '@src/interfaces/ICalendarMonth';
import { IEasyInfo, IEasyPay, IQrDetail, IQrInfo, ITransactionChartItem, ITransactionItem } from '@src/interfaces/ITransactionState';
import { formatMoney, getTimeFromTimeServer } from './format';
import { assetTopAnalyticsEvents } from '@src/analyticsEvents/assetTopAnalyticsEvents';
import { IStockProductState } from '@src/interfaces/IStockProductState';
import { IBundleInfo, IBundleResponse } from '@src/interfaces/IBundle';
import Router from 'next/router';
import {
  getBundleNotAppliedMsg,
  getFinancialPlanMemberCaseTwoMsg,
  getFinancialPlanMemberCaseThreeMsg
} from './financialBundleUtils';
export enum EXPRESSION_TYPE {
  EQUAL = '=',
  NOT_EQUAL = '!=',
  GREATER_THAN_OR_EQUAL = '>=',
  GREATER_THAN = '>',
  LESSOR_THAN_OR_EQUAL = '<=',
  LESSOR_THAN = '<',
  IN_ARRAY = 'in',
  MULTIPLE_CONDITIONS_AND = '&&',
  MULTIPLE_CONDITIONS_OR = '||',
};

export enum UPDATE_TYPE {
  AUTO_UPDATE = 'AUTO_UPDATE'
}

export enum TRACKING_PAGE {
  ON_ASSET_TOP = 'ON_ASSET_TOP',
  ON_ASSET_DETAIL = 'ON_ASSET_DETAIL',
}

export type CardType = 'isAuCard' | 'isMFCard' | '' | null;

export type CurrentAccountErrorMessages = { [key: string]: string }

export type SUM_TRANSACTION = { expense: number, income: number, existIncome: boolean };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getAnsweredQuestionBefore(conditional_expressions: any, listAnsweredQuestions: any) {
  let answeredQuestion = null;
  for (const answerKey in listAnsweredQuestions) {
    if (conditional_expressions[answerKey]) {
      answeredQuestion = listAnsweredQuestions[answerKey];
      break;
    }
  }
  return answeredQuestion;
};

// check to show or hide current question by expression type from before answered of question
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function checkIsShowQuestionByExpressionType(conditional_expressions: any, listAnsweredQuestions: any): boolean {
  let isShowCurrentQuestion = false;
  // eslint-disable-next-line no-eval
  conditional_expressions = eval('(' + conditional_expressions + ')');

  if (_isEmpty(conditional_expressions) || _isEmpty(listAnsweredQuestions)) {
    // show question
    return true;
  }

  // if conditional_expressions !== {};
  const answeredQuestionBefore = getAnsweredQuestionBefore(conditional_expressions, listAnsweredQuestions);
  /*
    Step:
      1. Find before answered
      2. Get before answered value
      3. Get before answered key
      3. Get expression type by answered key before from step 3
      4. Get values will match with before answered
      5. Check if value answered before match with values from step  by expression type to show or hide
  */
  if (answeredQuestionBefore) {
    const answeredQuestionValueBefore = answeredQuestionBefore.answer && answeredQuestionBefore.answer.value ? answeredQuestionBefore.answer.value : null;
    const answeredQuestionKeyBefore = answeredQuestionBefore.question_key || null;
    const expressionType: EXPRESSION_TYPE | null = (answeredQuestionKeyBefore && conditional_expressions[answeredQuestionKeyBefore])
      ? conditional_expressions[answeredQuestionKeyBefore]._exp : null;
    const valuesWillMatch = (answeredQuestionKeyBefore && conditional_expressions[answeredQuestionKeyBefore])
      ? conditional_expressions[answeredQuestionKeyBefore].value : null;
    if (!expressionType) {
      // show questionx`
      return false;
    }

    switch (expressionType) {
      case EXPRESSION_TYPE.EQUAL: {
        break;
      }
      case EXPRESSION_TYPE.NOT_EQUAL: {
        break;
      }
      case EXPRESSION_TYPE.GREATER_THAN_OR_EQUAL: {
        break;
      }
      case EXPRESSION_TYPE.GREATER_THAN: {
        break;
      }
      case EXPRESSION_TYPE.LESSOR_THAN_OR_EQUAL: {
        break;
      }
      case EXPRESSION_TYPE.LESSOR_THAN: {
        break;
      }
      case EXPRESSION_TYPE.IN_ARRAY: {
        if (_isArray(valuesWillMatch) && valuesWillMatch.indexOf(answeredQuestionValueBefore) > -1) {
          isShowCurrentQuestion = true;
        }
        break;
      }
      case EXPRESSION_TYPE.MULTIPLE_CONDITIONS_AND: {
        break;
      }
      case EXPRESSION_TYPE.MULTIPLE_CONDITIONS_OR: {
        break;
      }
      default:
        isShowCurrentQuestion = false;
        break;
    }
  }

  return isShowCurrentQuestion;
}

export function checkTokenIsExpired(): boolean {
  if (typeof sessionStorage !== 'undefined') {
    const token_expire = sessionStorage.getItem('token_expire') || '';
    if (token_expire) {
      // check expired or not
      if (token_expire && +token_expire) {
        const currentTime = new Date().getTime();
        const expireTime = (+token_expire * 1000) - 30000; // before real expire time on server 30s
        if (expireTime >= currentTime) { // is not expired
          return false;
        }
        return true;
      }

      return true;
    }
    return true;
  }

  return true;
}

export function saveTokenExpire(token: string) {
  if (token && typeof sessionStorage !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const decodedJwt: any = jwtDecode(token);
    if (decodedJwt && decodedJwt.exp && typeof decodedJwt.exp !== 'undefined' && +decodedJwt.exp) {
      sessionStorage.setItem('token_expire', `${decodedJwt.exp}`);
    }
  }
}

export function breakLine(text: string) {
  return text ? text.replace(/(?:\r\n|\r|\n)/g, '<br />') : text;
}

export function formatSubTypeName(text: string) {
  text = text ? text.replace('(', '<span class="sub-account-text">(').replace(')', ')</span>') : text;
  text = text ? text.replace('（', '<span class="sub-account-text">（').replace(')', '）</span>') : text;

  return text;
}

export function getFormatedName(accountName: string) {
  return accountName ? formatSubTypeName(breakLine(accountName)) : '';
}

export function addCheckIconLi(text: string) {
  return text.replace(/(?:<li>)/g, '<li><i class="kicon-checkbox-orange"></i>');
}

export function formatSavings(savings: number) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let savingsFormated: any = '';
  if (typeof savings === 'number') {
    if (Math.abs(savings) > 10000) {
      savingsFormated = (savings / 10000).toFixed(2);
    } else {
      savingsFormated = (savings.toFixed(2));
    }
  }

  return savingsFormated;
}

export function formatCurrency(n: number | string, separate = ',') {
  const s = n.toString();
  const regex = /\B(?=(\d{3})+(?!\d))/g;
  return s.replace(regex, separate);
}

export function setIdButtom(prefix = 'id') {
  const buttoms = document.getElementsByTagName('button');
  for (let i = 0; i < buttoms.length; i++) {
    const id = generateID(prefix, i);
    buttoms[i].setAttribute('id', id);
  }
}

export function generateID(prefix = 'id', num = 0) {
  return `${prefix}-${num}`;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function groupToActiveTab(data: any, activeTab: number) {
  const transactionData: any = [];
  data.forEach((item: any) => {
    if (activeTab === 1) {
      const transactions = _filter(item.transactions, (obj: any) => {
        return obj.is_income === false;
      });
      if (transactions.length) {
        transactionData.push({
          date: item.date,
          transactions
        });
      }
    } else {
      const transactions = _filter(item.transactions, (obj: any) => {
        return obj.is_income === true;
      });
      if (transactions.length) {
        transactionData.push({
          date: item.date,
          transactions
        });
      }
    }
  });
  return transactionData;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-explicit-any */

export function sumIncomeExpense(transactions: any, isAllTransactionsTotal?: boolean): SUM_TRANSACTION {
  const sumObject:SUM_TRANSACTION = _reduce(transactions, function(sumObject: SUM_TRANSACTION, transaction: any) {
    const isExecutedTotal = isAllTransactionsTotal || transaction.flag_excluded_from_calculation === false;

    if (transaction.is_income && isExecutedTotal) {
      sumObject.income += transaction.amount;
      sumObject.existIncome = true;
    }
    if (!transaction.is_income && isExecutedTotal) {
      sumObject.expense += transaction.amount;
    }
    return sumObject;
  }, { expense: 0, income: 0, existIncome: false });

  return sumObject;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function numberWithCommas(num: any) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace(/-/, '');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function replaceCommas(num: any) {
  return typeof num === 'number' ? num : num.replace(/,/g, '');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatValueTransaction(num: any) {
  return typeof num === 'number' ? `${Math.abs(num)}` : num.replace(/-/g, '');
}

export function checkCurrentPath(root_path: string, id?: string, time?: string) {
  const idQuery = id ? `?id=${id}` : '';
  const timeQuery = time ? `&time=${time}` : '';

  return `${getPathHasLastSlash(root_path)}${idQuery}${timeQuery}`;
}
export class MobileDetectDevice {
  static options = [];
  static header = typeof window !== 'undefined' ? [window.navigator.platform, window.navigator.userAgent, window.navigator.appVersion, window.navigator.vendor] : [];
  static dataos = [
    { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
    { name: 'Windows', value: 'Win', version: 'NT' },
    { name: 'iPhone', value: 'iPhone', version: 'OS' },
    { name: 'iPad', value: 'iPad', version: 'OS' },
    { name: 'Kindle', value: 'Silk', version: 'Silk' },
    { name: 'Android', value: 'Android', version: 'Android' },
    { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
    { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
    { name: 'Macintosh', value: 'Mac', version: 'OS X' },
    { name: 'Linux', value: 'Linux', version: 'rv' },
    { name: 'Palm', value: 'Palm', version: 'PalmOS' }
  ];

  static databrowser: [
    { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
    { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
    { name: 'Safari', value: 'Safari', version: 'Version' },
    { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
    { name: 'Opera', value: 'Opera', version: 'Opera' },
    { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
    { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
  ];

  public init = () => {
    var agent = MobileDetectDevice.header.join(' ');
    var os = this.matchItem(agent, MobileDetectDevice.dataos);
    var browser = this.matchItem(agent, MobileDetectDevice.databrowser);

    return { os: os, browser: browser };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private matchItem = (string: any, data: any = []) => {
    var i = 0;
    var j = 0;
    var regex;
    var regexv;
    var match;
    var matches;
    var version;

    for (i = 0; i < data.length; i += 1) {
      regex = new RegExp(data[i].value, 'i');
      match = regex.test(string);
      if (match) {
        regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
        matches = string.match(regexv);
        version = '';
        if (matches) { if (matches[1]) { matches = matches[1]; } }
        if (matches) {
          matches = matches.split(/[._]+/);
          for (j = 0; j < matches.length; j += 1) {
            if (j === 0) {
              version += matches[j] + '.';
            } else {
              version += matches[j];
            }
          }
        } else {
          version = '0';
        }
        return {
          name: data[i].name,
          version: parseFloat(version)
        };
      }
    }
    return { name: 'unknown', version: 0 };
  }
}

export function getCurrentMonth() {
  const currentDatetime = moment();
  const month = parseInt(currentDatetime.format('MM'));
  const year = parseInt(currentDatetime.format('YYYY'));
  const monthString = month < 10 ? '0' + month : month;

  return {
    currentYear: year,
    month: { value: monthString, label: `${month}月` }
  };
}

// time format: YYYY-MM
export function getMonthYearFromTime(time: string) {
  time = time || formatDate(true);
  const monthYear = time.split('-');
  const year = monthYear.length > 1 ? monthYear[0] : moment().format('YYYY');
  const month = monthYear.length > 1 ? monthYear[1] : moment().format('MM');

  return { month, year };
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function handleSort(currentFilter: string, transactions: Array<any>, transactionLimit?: number) {
  const transactionArray = transactions || [];
  transactionLimit = transactionLimit || transactionArray.length;

  transactionArray.map((transaction : any) => {
    const { card_type, time, history_date, proc_d_time } = transaction;
    const id = transaction.id || '';
    let fullTime = time;

    // Prepay( cardType =0)
    if (card_type === '0' && history_date) {
      fullTime = history_date;
    // QR ( cardType = 6)
    } else if (card_type === '6' && proc_d_time) {
      fullTime = proc_d_time;
    }

    transaction.fulltime = fullTime.replace('Z', '+09:00');
    transaction.seq_no = id.replace('swallow-', '').replace('mf-', '');
    const { year = '', month = '', day = '' } = getTimeFromTimeServer(transaction.fulltime);
    transaction.date = `${year}${month}${day}`;
  });

  switch (currentFilter) {
    case '1':
      return sortByDate('desc', transactionArray, transactionLimit);
    case '2':
      return sortByDate('asc', transactionArray, transactionLimit);
    case '3':
      return sortByAmount('desc', transactionArray, transactionLimit);
    case '4':
      return sortByAmount('asc', transactionArray, transactionLimit);
    default:
      return sortByDate('desc', transactionArray, transactionLimit);
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-explicit-any */
export function sortByDate(sortType: any, transactions: Array<any>, transactionLimit: number) {
  const sortTransaction = _orderBy(transactions, [
    'fulltime',
    (item: any) => item.seq_no.length,
    'seq_no'
  ], [sortType, sortType, sortType]);
  const limit = Math.min(transactionLimit, sortTransaction.length);

  return groupTransactionByDate(sortTransaction.slice(0, limit), true);
}
/* eslint-enable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-explicit-any */
export function sortByAmount(sortType: any, transactions: Array<any>, transactionLimit: number) {
  const sortTransaction = _orderBy(transactions, [
    (obj: any) => ((obj.amount < 0) ? Math.abs(obj.amount) - 0.5 : Math.abs(obj.amount)),
    (item: any) => item.seq_no.length,
    'seq_no'
  ], [sortType, sortType, sortType]);
  const limit = Math.min(transactionLimit, sortTransaction.length);

  return groupTransactionByDate(sortTransaction.slice(0, limit), false);
}
/* eslint-enable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-explicit-any */
export function groupTransactionByDate(transaction: Array<any>, hasGroup: boolean) {
  const data: any = [];

  if (hasGroup) {
    transaction.forEach(item => {
      const date = item.date;
      const exsist = _find(data, (obj: any) => {
        return obj.date === date;
      });
      if (exsist) {
        exsist.transactions.push(item);
      } else {
        data.push({
          date: item.date,
          transactions: [item]
        });
      }
    });
  } else {
    transaction.forEach(item => {
      data.push({
        date: item.date,
        transactions: [item]
      });
    });
  }
  return data;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export function getRecommendService(keyword: string, services: Array<IRecommend>) {
  let recommendSevice:IRecommend = { id: '', service_name: '' };
  const serviceLength = services.length;

  if (serviceLength === 1) {
    recommendSevice = services[0];
  } else if (serviceLength > 1) {
    recommendSevice.service_name = keyword;
  }

  return recommendSevice;
}

export function getAccountType(dataSource: number|string) {
  switch (+dataSource) {
    case 2: return AccountType.au_prepaid;
    case 3: return AccountType.au_credit;
    case 4: return AccountType.au_jbank;
    case 5: return AccountType.au_easy_pay;
    case 8: return AccountType.emoney;
    case 9: return AccountType.mf_point;
    case 10: return AccountType.au_ponta;
    case 12: return AccountType.au_market;
    case 13: return AccountType.au_ponta_market;
    case 16: return AccountType.au_point_unyo;
    case 17: return AccountType.au_kantan_kessai;
    case 18: return AccountType.au_stock;
    // MF Accounts: auStock (new) = 18; auStock (old) = 14 MFPoint=9
    // auStock is MF Acc because MF API returns au_stock.
  }

  return '';
}

export function disableScroll(disable: boolean) {
  const Next = document.getElementById('__next');
  const scrollChan = document.getElementById('scroll-chan');
  if (Next && scrollChan && disable) {
    Next.style.position = 'fixed';
    Next.style.right = '0';
    Next.style.left = '0';
    Next.style.top = '0';
    scrollChan.style.overflow = 'hidden';
  } else if (Next && scrollChan && !disable) {
    Next.style.position = 'relative';
    scrollChan.style.overflow = 'auto';
    Next.style.right = 'initial';
    Next.style.top = 'initial';
    Next.style.left = 'initial';
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatPositiveOrNegative = (number: any) => (
  number > 0 ? `+${parseFloat(number.toFixed(1))}` : number < 0 ? parseFloat(number.toFixed(1)) : 0
);

export const getPathHasLastSlash = (pathname: string) => {
  const hasLastSlash = pathname ? (pathname.substr(pathname.length - 1) === '/') : true;

  return hasLastSlash ? pathname : `${pathname}/`;
};

export const isAcceptPolicy = (user: IUserInfo) => {
  return (user.is_accept_webapp_term && user.is_accept_kabu_term);
};

export const getServiceType = (alias: string, isType?: boolean) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const item = _find(SERVICE_TYPES, (obj: any) => obj.alias === alias);

  return item ? (isType ? item.type : item.linkText) : '';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getShowingMonth = (month: any) => (month.charAt(0) === '0' ? month.substr(1) : month);

export const getSliceString = (maxLength: number | undefined, value: string) => {
  return value && maxLength && value.length > maxLength ? value.slice(0, maxLength) : value;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortJpTexts = (array: Array<any>, sortName: string) => {
  const collate = new Intl.Collator('ja');

  return array.sort((a, b) => {
    return collate.compare(a[sortName], b[sortName]);
  });
};

// push sentry when AuStock redirect with get error
export const sendStatusStockRedirectToSentry = (kpfmID: string, errMsg: string) => {
  if (kpfmID && errMsg) {
    const { captureMessage } = Sentry();

    const sentryError = {
      title: 'AuStock redirect NG',
      message: `AuStock redirect NG: kpfm_id: ${kpfmID} , status: ${errMsg}`,
      statusCode: ''
    };
    return captureMessage(sentryError, { errorInfo: sentryError, query: {} });
  }
};

export const getAppVersion = (ua: string) => {
  try {
    const matchKey = 'auWALLET';
    const matchKeyLength = matchKey.length;
    const pos = ua.indexOf(matchKey);
    if (pos === -1) {
      return '7.6.0';
    }
    const appVersion = ua.slice(pos + matchKeyLength + 1);
    const appVersionArr = appVersion.split(' ');

    return appVersionArr.length > 0 ? appVersionArr[0] : '7.6.0';
  } catch (error) {
    return '7.6.0';
  }
};

export const getAuPayID = (accountList: IAccountItem[]) => {
  const account = _find(accountList, (item: IAccountItem) => getAccountType(item.data_source) === AccountType.au_prepaid);
  return account ? account.id : undefined;
};

export function versionCompare(v1: string, v2: string) {
  const v1parts = v1.split('.');
  const v2parts = v2.split('.');
  const length = Math.min(v1parts.length, v2parts.length);

  for (var i = 0; i < length; ++i) {
    const number1 = parseInt(v1parts[i]);
    const number2 = parseInt(v2parts[i]);

    if (v2parts.length == i) {
      return 1;
    }

    if (number1 == number2) {
      continue;
    } else if (number1 > number2) {
      return 1;
    } else {
      return -1;
    }
  }

  if (v1parts.length != v2parts.length) {
    return -1;
  }

  return 0;
}

export const isStockAccount = (data_source: number) => {
  const { au_stock } = AccountType;

  return [au_stock].includes(getAccountType(data_source));
};

export const getLastAcquisition = (data_source: number, last_succeeded_at?: string, status?: number, createDate?: string) => {
  last_succeeded_at = last_succeeded_at || '';
  let lastAcquisitionAt = '';
  const isStockAcc = isStockAccount(data_source);

  if (isStockAcc && (status === 0 || status === 1)) {
    // show last_succeeded_at incase status = 0
    // or acc is processing and had linked succeess before (linked succeess before: lastSucceededAt != null)
    lastAcquisitionAt = last_succeeded_at ? moment(last_succeeded_at, 'YYYY-MM-DDTHH:ss:ii+Z').format('MM/DD H:ss') : '';
  } else if (!isStockAcc) { // incase Au acc
    lastAcquisitionAt = createDate ? moment(createDate, 'YYYY-MM-DDTHH:ss:ii+Z').format('MM/DD H:ss') : '';
  }

  return lastAcquisitionAt;
};

export const isNewLinkedAccount = (linked_at: string = '') => {
  const linkAt = linked_at ? moment(linked_at).unix() : '';

  return linkAt;
};

export const isErrSpecialAcc = (serviceId: number | string = '', linkedAt: string) => {
  /*
  condition for err special acc
  ・service_id：22（モバイルSuica）/ service_id：4541（モバイルPASMO）
  */
  const isSpecialAcc = +serviceId === 22 || +serviceId === 4541;
  return isSpecialAcc && !isNewLinkedAccount(linkedAt);
};

const layoutAuError = {
  ERR_ARROW: {
    theme: 'error',
    exclamation_mark: false,
    showingPrice: false,
    create_date: '',
    has_arrow_icon: true,
    text: '',
    karteEvent: '',
    message: '情報を表示できませんでした\n時間をおいて再度お試しください',
    messageUrl: '',
    contactMsg: '',
    linkRedirect: '',
    campaignText: '',
    campaignTextUrl: '',
    gaContactText: '',
    contactTextUrl: '',
    karteID: ''
  },
  ERR_NOT_ARROW: {
    theme: 'error',
    exclamation_mark: false,
    showingPrice: false,
    create_date: '',
    has_arrow_icon: false,
    text: '',
    karteEvent: '',
    message: '法人契約の場合ご利用いただけません',
    messageUrl: '',
    contactMsg: '',
    linkRedirect: '',
    campaignText: '',
    campaignTextUrl: '',
    gaContactText: '',
    contactTextUrl: '',
    karteID: ''
  },
  NORMAL: {
    theme: 'normal',
    showingPrice: false,
    exclamation_mark: false,
    has_arrow_icon: true,
    text: '',
    karteEvent: '',
    message: '',
    messageUrl: '',
    contactMsg: '',
    create_date: '',
    linkRedirect: '',
    campaignText: '',
    campaignTextUrl: '',
    gaContactText: '',
    contactTextUrl: '',
    karteID: ''
  },
  NEW_ACC: {
    theme: 'new_acc',
    exclamation_mark: false,
    showingPrice: false,
    create_date: '',
    has_arrow_icon: true,
    text: '口座連携する',
    karteEvent: '',
    message: '',
    messageUrl: '',
    linkRedirect: '',
    campaignText: '',
    campaignTextUrl: '',
    contactMsg: '',
    gaContactText: '',
    contactTextUrl: '',
    karteID: ''
  },
  DEFAULT: {
    theme: 'error',
    exclamation_mark: true,
    showingPrice: false,
    create_date: '',
    has_arrow_icon: true,
    text: '',
    karteEvent: '',
    message: '情報を表示できませんでした<br />時間をおいて再度お試しください',
    messageUrl: '',
    contactMsg: '',
    linkRedirect: '',
    campaignText: '',
    campaignTextUrl: '',
    gaContactText: '',
    contactTextUrl: '',
    karteID: ''
  }
};

// data_source = 2
const getAuPrepaidObj = (
  id: string,
  status: number,
  publicRuntimeConfig: {[key: string]: string}
) => {
  const { AUWALLET_TOP_SCHEMA, APP_DOMAIN } = publicRuntimeConfig;
  let auPrepaidObj = { ...layoutAuError.ERR_ARROW };

  switch (status) {
    case 0:
      if (!id) {
        auPrepaidObj = {
          ...layoutAuError.NEW_ACC,
          text: '新規発行する',
          karteID: 'au_pay_text',
          message: '',
          linkRedirect: `${APP_DOMAIN}https://wallet.auone.jp/contents/sp/app/entry.html`
        };
      } else {
        auPrepaidObj = {
          ...layoutAuError.ERR_NOT_ARROW,
          message: '情報を表示できませんでした'
        };
      }
      break;
    case 1:
      if (!id) {
        auPrepaidObj = {
          ...layoutAuError.ERR_NOT_ARROW,
          message: 'カードが届きましたら、HOMEより<br />ご利用開始の手続きを行ってください'
        };
      } else {
        auPrepaidObj = {
          ...layoutAuError.ERR_NOT_ARROW,
          message: '情報を表示できませんでした'
        };
      }
      break;
    case 2:
      auPrepaidObj.message = 'カードが届きましたら、HOMEより<br />ご利用開始の手続きを行ってください';
      auPrepaidObj.linkRedirect = AUWALLET_TOP_SCHEMA;
      break;
    case 3:
      auPrepaidObj.message = 'カードが届きましたら、HOMEより<br />ご利用開始の手続きを行ってください';
      auPrepaidObj.linkRedirect = AUWALLET_TOP_SCHEMA;
      break;
    case 70:
      auPrepaidObj.message = '情報を表示できませんでした<br />時間をおいて再度お試しください';
      auPrepaidObj.linkRedirect = AUWALLET_TOP_SCHEMA;
      break;
    case 80:
      auPrepaidObj = { ...layoutAuError.ERR_NOT_ARROW };
      auPrepaidObj.message = '法人契約の場合ご利用いただけません';
      break;
    case 90:
      auPrepaidObj.message = '情報を表示できませんでした<br />時間をおいて再度お試しください';
      auPrepaidObj.linkRedirect = AUWALLET_TOP_SCHEMA;
      break;
    // handle logic doesn't display error incase data from auPayApp has balance is empty
    case 100:
      auPrepaidObj.message = '';
      auPrepaidObj.linkRedirect = AUWALLET_TOP_SCHEMA;
      break;
  }
  return auPrepaidObj;
};
// data_source = 3
const getAuCreditObj = (
  id: string,
  status: number,
  publicRuntimeConfig: {[key: string]: string}
) => {
  const { AUWALLET_TOP_SCHEMA, APP_DOMAIN } = publicRuntimeConfig;
  let auCreditObj = { ...layoutAuError.ERR_ARROW };

  switch (status) {
    case 0: {
      if (!id) {
        auCreditObj = {
          ...layoutAuError.NEW_ACC,
          text: '新規発行する',
          karteID: 'au_pay_card_text',
          message: '',
          linkRedirect: `${APP_DOMAIN}https://ac.ebis.ne.jp/tr_set.php?argument=HWSEHByQ&ai=2309_app_kanri01`,
          // TODO: remove or update after June
          // this text only a condition for random array
          // if there are no bundle message, this text will not be used to render
          campaignText: 'credit-bundle-condition',
          // eslint-disable-next-line no-useless-escape
          campaignTextUrl: `${APP_DOMAIN}https://ac.ebis.ne.jp/tr\_set.php?argument=HWSEHByQ&ai=2212\_app\_kanri01`,
          karteEvent: 'auPAY'
        };
      } else {
        auCreditObj = {
          ...layoutAuError.ERR_NOT_ARROW,
          message: '情報を表示できませんでした'
        };
      }
      break;
    }
    case 1: {
      if (!id) {
        auCreditObj = {
          ...layoutAuError.NEW_ACC,
          text: '新規発行する',
          karteID: 'au_pay_card_text',
          message: '',
          linkRedirect: `${APP_DOMAIN}https://ac.ebis.ne.jp/tr_set.php?argument=HWSEHByQ&ai=2309_app_kanri01`,
          // TODO: remove or update after June
          campaignText: '',
          // eslint-disable-next-line no-useless-escape
          campaignTextUrl: `${APP_DOMAIN}https://ac.ebis.ne.jp/tr\_set.php?argument=HWSEHByQ&ai=2212\_app\_kanri01`,
          karteEvent: 'auPAY'
        };
      } else {
        auCreditObj = {
          ...layoutAuError.ERR_NOT_ARROW,
          message: '情報を表示できませんでした'
        };
      }
      break;
    }
    case 2: {
      if (!id) {
        auCreditObj = {
          ...layoutAuError.ERR_NOT_ARROW,
          message: 'au PAY カードをお申し込み中です'
        };
      } else {
        auCreditObj = {
          ...layoutAuError.ERR_NOT_ARROW,
          message: '情報を表示できませんでした'
        };
      }
      break;
    }
    case 4: {
      auCreditObj = {
        ...layoutAuError.ERR_NOT_ARROW,
        message: 'カードを受領された約２営業日後から<br />ご請求金額が表示されます'
      };
      break;
    }
    case 5: {
      auCreditObj = {
        ...layoutAuError.ERR_NOT_ARROW,
        message: 'ご利用のau IDにau契約のご登録がありません'
      };
      break;
    }
    case 60: {
      if (!id) {
        auCreditObj = {
          ...layoutAuError.ERR_NOT_ARROW,
          message: 'au PAY カードをお申し込みいただけません'
        };
      } else {
        auCreditObj = {
          ...layoutAuError.ERR_NOT_ARROW,
          message: '情報を表示できませんでした'
        };
      }
      break;
    }
    case 70: {
      auCreditObj.message = '情報を表示できませんでした<br />時間をおいて再度お試しください';
      auCreditObj.linkRedirect = AUWALLET_TOP_SCHEMA;
      break;
    }
    case 80: {
      auCreditObj = {
        ...layoutAuError.ERR_NOT_ARROW,
        message: '法人契約の場合ご利用いただけません'
      };
      break;
    }
    case 90: {
      auCreditObj.message = '情報を表示できませんでした<br />時間をおいて再度お試しください';
      auCreditObj.linkRedirect = AUWALLET_TOP_SCHEMA;
      break;
    }
  }

  return auCreditObj;
};

// data_source = 4
const getAuJBankObj = (
  id: string,
  status: number,
  publicRuntimeConfig: {[key: string]: string}
) => {
  const { APP_DOMAIN, AUWALLET_TOP_SCHEMA } = publicRuntimeConfig;
  let auJBankObj = { ...layoutAuError.ERR_ARROW };
  const jibunbank01Url = `${APP_DOMAIN}https://pfm.wallet.auone.jp/finance/bank/program_charge5.html?code=PM2501Y13201&utm_source=pfm&utm_medium=btn&utm_campaign=main`;
  const jiginApplyUrl = 'auwallet://jibunbank';

  switch (status) {
    case 1: {
      if (!id) {
        auJBankObj = {
          ...layoutAuError.NEW_ACC,
          text: '口座開設する',
          karteID: 'au_jbank_text',
          linkRedirect: jibunbank01Url,
          campaignText: '<span>auじぶん銀行ならau PAY残高へのオートチャージが可能です</span>',
          contactMsg: "口座をお持ちの方で、連携は<a class='underline-text'>こちら</a>",
          gaContactText: '連携はこちら',
          contactTextUrl: jiginApplyUrl,
          karteEvent: 'jBank'
        };
      } else {
        auJBankObj = {
          ...layoutAuError.ERR_NOT_ARROW,
          message: '情報を表示できませんでした'
        };
      }
      break;
    }
    case 2: {
      if (!id) {
        auJBankObj = {
          ...layoutAuError.NEW_ACC,
          text: '口座開設する',
          karteID: 'au_jbank_text',
          linkRedirect: jibunbank01Url,
          campaignText: '<span>auじぶん銀行ならau PAY残高へのオートチャージが可能です</span>',
          contactMsg: "口座をお持ちの方で、連携は<a class='underline-text'>こちら</a>",
          gaContactText: '連携はこちら',
          contactTextUrl: jiginApplyUrl,
          karteEvent: 'jBank'
        };
      } else {
        auJBankObj = {
          ...layoutAuError.ERR_NOT_ARROW,
          message: '情報を表示できませんでした'
        };
      }
      break;
    }
    case 4: {
      if (!id) {
        auJBankObj = {
          ...layoutAuError.NEW_ACC,
          text: '口座開設する',
          karteID: 'au_jbank_text',
          linkRedirect: jibunbank01Url,
          campaignText: '<span>auじぶん銀行ならau PAY残高へのオートチャージが可能です</span>',
          contactMsg: "口座をお持ちの方で、連携は<a class='underline-text'>こちら</a>",
          gaContactText: '連携はこちら',
          contactTextUrl: jiginApplyUrl,
          karteEvent: 'jBank'
        };
      } else {
        auJBankObj = {
          ...layoutAuError.ERR_NOT_ARROW,
          message: '情報を表示できませんでした'
        };
      }
      break;
    }
    case 70: {
      auJBankObj = {
        ...layoutAuError.ERR_ARROW,
        message: '情報を表示できませんでした<br />時間をおいて再度お試しください',
        linkRedirect: AUWALLET_TOP_SCHEMA
      };
      break;
    }
    case 90: {
      auJBankObj = {
        ...layoutAuError.ERR_ARROW,
        message: '情報を表示できませんでした<br />時間をおいて再度お試しください',
        linkRedirect: AUWALLET_TOP_SCHEMA
      };
      break;
    }
    // handle logic doesn't display error incase data from auPayApp has balance is empty
    case 100: {
      auJBankObj = {
        ...layoutAuError.ERR_ARROW,
        message: '',
        linkRedirect: AUWALLET_TOP_SCHEMA
      };
      break;
    }
  }

  return auJBankObj;
};

// data source = 17
export function getKantanObj(
  status: number,
  kantanInf: IKantanInfo
) {
  const kantanApproveErrInfo = getKantanApproveErrorInfo(kantanInf.kantan_kiyaku, status);
  const { message = '', url = '' } = kantanApproveErrInfo || {};

  let kantanObj;
  switch (status) {
    case 70: {
      kantanObj = {
        ...layoutAuError.ERR_ARROW,
        message: '情報を表示できませんでした<br/>時間をおいて再度お試しください'
      };
      break;
    }
    case 90: {
      kantanObj = {
        ...layoutAuError.ERR_ARROW,
        message: message,
        messageUrl: url
      };
      break;
    }
    default: {
      kantanObj = {
        ...layoutAuError.ERR_ARROW,
        message: '情報を表示できませんでした<br/>時間をおいて再度お試しください'
      };
    }
  }
  return kantanObj;
}

function getAuPonta(status: number, accountInfo: IPointInfo) {
  let auPontaObj = { ...layoutAuError.ERR_ARROW };
  const { pontaLink } = accountInfo;

  if (status === 70 && ['0', '1'].includes(pontaLink)) {
    auPontaObj.message = '情報を表示できませんでした<br />時間をおいて再度お試しください';
  } else if (status === 80 && ['0', '1'].includes(pontaLink)) {
    auPontaObj = { ...layoutAuError.ERR_NOT_ARROW };
    auPontaObj.message = '法人契約の場合ご利用いただけません';
  } else if (status === 90 && ['0', '1'].includes(pontaLink)) {
    auPontaObj.message = '情報を表示できませんでした<br />時間をおいて再度お試しください';
  } else {
    auPontaObj.message = '';
  }

  return auPontaObj;
}

function getAuMarket(publicRuntimeConfig: {[key: string]: string}) {
  const { APP_DOMAIN } = publicRuntimeConfig;
  let auMarket = { ...layoutAuError.ERR_ARROW };

  auMarket = {
    ...layoutAuError.NEW_ACC,
    text: '利用開始する',
    karteID: 'au_market_text',
    linkRedirect: `${APP_DOMAIN}https://wallet.auone.jp/contents/sp/aupay_app/redirect/miniapp_version_filter.html?service=au_pay_market_prod&addition_param=target_url%3Dhttps%253A%252F%252Fwowma.jp%252Fevent%252Fwmkt%252Findex.html%253Faff_id%253Dpay35llXts210317e990331Xosp00Xmal%26aff_id%3Dpay35llXps210317e990331Xosp00Xmal`
  };
  return auMarket;
}

function getAuPontaMarket(status: number, accountInfo: IPointInfo) {
  let auPontaMarket = { ...layoutAuError.ERR_ARROW };
  const { pontaLink } = accountInfo;

  if (status === 70 && ['0', '1', ''].includes(pontaLink)) {
    auPontaMarket.message = '情報を表示できませんでした<br />時間をおいて再度お試しください';
  } else if (status === 80 && ['0', '1', ''].includes(pontaLink)) {
    auPontaMarket = { ...layoutAuError.ERR_NOT_ARROW };
    auPontaMarket.message = '法人契約の場合ご利用いただけません';
  } else if (status === 90 && ['0', '1', ''].includes(pontaLink)) {
    auPontaMarket.message = '情報を表示できませんでした<br />時間をおいて再度お試しください';
  } else {
    auPontaMarket.message = '';
  }

  return auPontaMarket;
}

function getAuPointUnyo(id: string, status: number, accountInfo: IPointInfo, publicRuntimeConfig: {[key: string]: string}) {
  let auPointUnyo = { ...layoutAuError.ERR_ARROW };
  const { response_code } = accountInfo;
  const { APP_DOMAIN } = publicRuntimeConfig;
  const url = `${APP_DOMAIN}https://wallet.auone.jp/contents/sp/aupay_app/redirect/miniapp_version_filter.html?service=am_point_prod&hide_header=1&addition_param=utm_source%3Dpfm%26utm_medium%3Dprepointinv%26utm_campaign%3Dpfm`;

  if (status === 200 && response_code === 1) {
    auPointUnyo = { ...layoutAuError.NEW_ACC };
    auPointUnyo.text = '運用してみる?';
    auPointUnyo.karteID = 'au_point_unyo_text_2';
    auPointUnyo.linkRedirect = url;
  } else if (!id && status === 200 && response_code === 9) {
    auPointUnyo = { ...layoutAuError.NEW_ACC };
    auPointUnyo.text = '利用開始する';
    auPointUnyo.karteID = 'au_point_unyo_text_1';
    auPointUnyo.linkRedirect = url;
  } else {
    auPointUnyo = { ...layoutAuError.ERR_ARROW };
    auPointUnyo.message = '情報を表示できませんでした<br />時間をおいて再度お試しください';
    auPointUnyo.has_arrow_icon = false;
  }

  return auPointUnyo;
};

export const getAuObj = (
  id: string,
  data_source: number,
  status: number,
  pointInfo: IPointInfo | undefined,
  kantanInfo: IKantanInfo | undefined
) => {
  const config = getConfig() || {};
  const { publicRuntimeConfig = {} } = config;
  const type = getAccountType(data_source);
  const { au_prepaid, au_credit, au_jbank, au_ponta, au_ponta_market, au_market, au_point_unyo, au_kantan_kessai } = AccountType;
  const accountInfo = pointInfo || initialPointInfo;
  const kantanKessaiInf = kantanInfo || intitialKantanInfo;

  let auOject;

  // https://docs.google.com/spreadsheets/d/14awjZEjCGzMkaFCJSkrqDBIa5W93Jn4E/edit#gid=379332894
  switch (type) {
    case au_prepaid: auOject = getAuPrepaidObj(id, status, publicRuntimeConfig); break;
    case au_credit: auOject = getAuCreditObj(id, status, publicRuntimeConfig); break;
    case au_jbank: auOject = getAuJBankObj(id, status, publicRuntimeConfig); break;
    case au_ponta: auOject = getAuPonta(status, accountInfo); break;
    case au_market: auOject = getAuMarket(publicRuntimeConfig); break;
    case au_ponta_market: auOject = getAuPontaMarket(status, accountInfo); break;
    case au_point_unyo: auOject = getAuPointUnyo(id, status, accountInfo, publicRuntimeConfig); break;
    case au_kantan_kessai: auOject = getKantanObj(status, kantanKessaiInf); break;

    // The default layout will have is_error = false (from Back-End), just keep for backup logic.
    default: auOject = layoutAuError.DEFAULT;
  }

  return auOject;
};

export const redirectExternalUrl = (link: string) => {
  console.log('redirectExternalUrl', link);
  window.location.href = link;
};

export const redirectInternalUrl = (link: string) => {
  Router.push(link);
};

export const storeErrAcc = (id: string, errCurrAcc: ErrorCurrentAccount) => {
  const storedAccs = localStorage && localStorage.getItem(localStorageKeys.CURRENT_ACCOUNTS);
  let currAccs: ErrorCurrentAccounts = {};

  if (storedAccs) {
    currAccs = JSON.parse(storedAccs);
    currAccs[id] = errCurrAcc;
  } else {
    currAccs = { [id]: errCurrAcc };
  }

  localStorage && localStorage.setItem(localStorageKeys.CURRENT_ACCOUNTS, JSON.stringify(currAccs));
};

// AuStock got no maintenance
export const isMaintenanceAccount = (data_source: number, maintenance_status: number) => {
  const isAuStock = isStockAccount(data_source);
  const isAuMaintenance = maintenance_status === MAINTENANCE_STATUS.SWALLOW_MAINTENANCE;
  const isMaintenance = !isAuStock && isAuMaintenance;

  return maintenance_status === MAINTENANCE_STATUS.BOTH_MAINTENANCE || isMaintenance;
};

export const isShowMaintenanceModal = (maintenance_status: number) => {
  return !(maintenance_status === MAINTENANCE_STATUS.NO_MAINTENANCE);
};

export const existsDebtBalance = (dataSource: number) => {
  const isAuCard = ['au-credit'].includes(getAccountType(dataSource));

  return {
    haveDebtBalance: isAuCard
  };
};

export function hexToRgb(hex: string, alpha:number) {
  hex = hex.replace('#', '');
  var r = parseInt(hex.length == 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16);
  var g = parseInt(hex.length == 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16);
  var b = parseInt(hex.length == 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16);
  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
}

export function validURL(str: string) {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

export function getAndRemovePrevRoute(isNotRemovePrevRouteInHistory?: boolean) {
  if (!sessionStorage) return pathConstant.topPath;
  const historyRoutes = JSON.parse(sessionStorage.getItem('historyRoutes') || '[]');
  // remove current path in historyRoutes
  historyRoutes.length && historyRoutes.pop();
  // get prevRoute
  let prevRoute = historyRoutes.length ? historyRoutes.pop() : pathConstant.topPath;
  prevRoute = prevRoute === '/' ? pathConstant.topPath : prevRoute;

  !isNotRemovePrevRouteInHistory && sessionStorage.setItem('historyRoutes', JSON.stringify(historyRoutes));
  return prevRoute;
}
// SIZE_OF_A_BAR_CHART(43px) = size of a bar chart(26px) + space around a bar chart(17px)
export const SIZE_OF_A_BAR_CHART = 43;

export function getQuatityBarChartInViewPort() {
  return Math.ceil(window.innerWidth / SIZE_OF_A_BAR_CHART) + 1;
}

export function isChoosingCalendarValue(transaction: ITransactionChartItem, calendarMonthValue: ICalendarMonthValue) {
  const { currentYear: yearCalendar, month: { value: monthCalendar } = { label: '', value: '' } } = calendarMonthValue;
  const { time } = transaction;
  const monthValue = time ? moment(time, 'YYYY-MM').format('M') : 0;
  const yearValue = time ? moment(time, 'YYYY-MM').format('YYYY') : '0000';
  return (+yearCalendar === +yearValue && +monthCalendar === +monthValue);
};

export function findIndexOfCalendarValue(calendarMonthValue: ICalendarMonthValue, transactionsChart: ITransactionChartItem[] = []) {
  return transactionsChart.findIndex((transaction: ITransactionChartItem) => isChoosingCalendarValue(transaction, calendarMonthValue));
}

export function getPointAccName(name: string, servicesName: string) {
  const mainName = name || servicesName;
  // only show service_name incase service_name !== asset_name
  const subName = (name && name !== servicesName) ? servicesName : '';
  const formatedServiceName = subName.length > 12 ? `${subName.slice(0, 12)}...` : subName;

  return { mainName, subName, formatedServiceName };
}

export function removeUnusedAccountInLocalStorage(id: string) {
  const currentAccountString = localStorage ? localStorage.getItem(localStorageKeys.CURRENT_ACCOUNTS) : '';
  if (!currentAccountString) return;

  const currentAccounts = JSON.parse(currentAccountString);
  const newCurrentAccounts = { ...currentAccounts };
  const keys = Object.keys(currentAccounts);

  keys.forEach((key: string) => {
    const item = currentAccounts[key];
    const { accountInfo = initialPointInfo } = item;

    if (item.id === id || (accountInfo && accountInfo.main_account_id === id)) {
      delete newCurrentAccounts[item.id];
    }
  });

  localStorage.setItem(localStorageKeys.CURRENT_ACCOUNTS, JSON.stringify(newCurrentAccounts));
}

export const isRightAssetDetailUrl = (pathname: string, query: {[key: string]: string}) => {
  const { userAgent } = window.navigator;
  const isAssetDetailPage = getPathHasLastSlash(pathname) === pathConstant.assetDetailPath;
  const isUserAgentAuWallet = isEnvNeedUserAgent() ? userAgent.indexOf('auWALLET') > -1 : true;
  const isUserAgentAuPaySDK = isEnvNeedUserAgent() ? userAgent.indexOf('auPaySDK') > -1 : true;
  const isRightDetailUrl = isAssetDetailPage && query.id && (isUserAgentAuWallet || isUserAgentAuPaySDK);

  return isRightDetailUrl;
};

export const isRightSDKUrl = (pathname: string, query: {[key: string]: string}) => {
  const { userAgent } = window.navigator;
  const currentPath = getPathHasLastSlash(pathname);
  const isSDKPage = [pathConstant.assetAuPayPath, pathConstant.assetDetailPath].includes(currentPath);
  const { service, issdk, initdisp, graph, transfer } = query;
  const existSDKParameter = service !== undefined || issdk !== undefined || initdisp !== undefined || graph !== undefined || transfer !== undefined;
  const isUserAgentAuWallet = isEnvNeedUserAgent() ? userAgent.indexOf('auWALLET') > -1 : true;
  const isUserAgentAuPaySDK = isEnvNeedUserAgent() ? userAgent.indexOf('auPaySDK') > -1 : true;
  const isSdkUrl = isSDKPage && existSDKParameter && (isUserAgentAuWallet || isUserAgentAuPaySDK);

  return !!isSdkUrl;
};

export const isEnvNeedUserAgent = () => {
  const { publicRuntimeConfig = {} } = getConfig() || {};
  const { APP_ENV } = publicRuntimeConfig;

  return ['test', 'staging', 'production'].includes(APP_ENV);
};

export const isPointAcc = (accType: string) => {
  const { au_ponta, au_ponta_market, au_point_unyo } = AccountType;

  return [au_ponta, au_ponta_market, au_point_unyo].includes(accType);
};

export const isPayNetQrTran = (qrInfo: IQrInfo | undefined) => {
  let isPayNet = false;

  if (qrInfo && qrInfo.qr_list) {
    const detailQrList = qrInfo.qr_list || [];
    const paynetStatus = _find(detailQrList, (item: IQrDetail) => ['2002', '2006', '2012'].includes(item.proc_type));

    isPayNet = !!paynetStatus;
  }

  return isPayNet;
};

export const isPayNetEasyPayTran = (easyInfo: IEasyInfo | undefined) => {
  let isPayNetEasyPay = false;

  if (easyInfo && easyInfo.easy_pay_list) {
    const detailList = easyInfo.easy_pay_list || [];
    const paynetStatus = _find(detailList, (item: IEasyPay) => ['80', '81', '83', '84', '82', '85', '86', '87'].includes(item.paymnt_method_cd));

    isPayNetEasyPay = !!paynetStatus;
  }

  return isPayNetEasyPay;
};

/**
 * datetime is start time of date when its value has format YYYY-MM-DD 00:00:00
 * @param datetime: format YYYYMMDDhhmmss, YYYY-MM-DD hh:mm:ss
 * @returns
 */
export const isStartTimeOfDay = (datetime: string) => {
  if (!datetime) return false;
  const { time } = getTimeFromTimeServer(datetime.replace('Z', '+09:00'));

  return time === '00:00:00';
};

export const getAssetCurrentTime = () => {
  const currentDatetime = moment();
  const month = parseInt(currentDatetime.format('MM'));
  const year = parseInt(currentDatetime.format('YYYY'));
  const monthString = month < 10 ? `0${month}` : month;

  return {
    currentYear: year,
    month: { value: monthString, label: `${monthString}月` }
  };
};
export const groupStockProducts = (products: Array<IStockProductState>) => {
  const groups = _groupBy(products, (item: IStockProductState) => item.info.asset_class_id);

  // show products of au stock (service_id = 14) at the first list
  _foreach(groups, (ele:Array<IStockProductState>, key: string) => {
    const auStockProducts = _filter(ele, (item:IStockProductState) => item.service_id === 14);
    const otherProducts = _filter(ele, (item:IStockProductState) => item.service_id !== 14);

    groups[key] = auStockProducts.concat(otherProducts);
  });

  return groups;
};

export const handleServiceName = (serviceName: string = '', transaction: ITransactionItem) => {
  let name = serviceName || (transaction.account ? transaction.account.service_name : '');
  const accType = getAccountType(transaction.data_source);
  const isAuPayCard = accType === AccountType.au_credit;

  if (isAuPayCard && transaction.member_type === '0') {
    name = `${name} 本人`;
  } else if (isAuPayCard && transaction.member_type === '1') {
    name = `${name} 家族カード`;
  }
  return name;
};

export const getScreenNameByDataSource = (dataSouce: number) => {
  const screen = getAccountType(dataSouce);
  switch (screen) {
    case AccountType.au_jbank:
      return 'au_jbank_asset_detail';
    case AccountType.au_market:
      return 'au_pay_market_asset_detail';
    case AccountType.au_ponta_market:
      return 'ponta_point_market_asset_detail';
    case AccountType.au_kantan_kessai:
      return 'au_kantan_kessai_asset_detail';
    default:
      return 'asset_top';
  }
};

export const trackingDisplayForMsgOfNewAccounts = (accountType: string) => {
  // if accountType equal to ALL, that's mean there are no unlinked account
  if (accountType !== 'ALL') {
    const trackingData = TRACKING_DATA[accountType];
    const { action } = trackingData;
    assetTopAnalyticsEvents.eventDisplayMsgOfNewAccounts('【visible】トップ', action);
  }
};

export const getKantanApproveErrorInfo = (kantanKiyaku: IKantanKiyaku, status: number) => {
  const isAprroveErrCase = [90, 0].includes(status);

  if (isAprroveErrCase && kantanKiyaku.acptStat === '0') {
    return {
      message: '情報を表示するには<u>こちら</u>',
      url: 'https://id.auone.jp/payment/limitrefer/credit.html'
    };
  }

  if (
    isAprroveErrCase &&
    kantanKiyaku.useLimitAmtSet !== '0' &&
    !!kantanKiyaku.crdtlmtContent &&
    !!kantanKiyaku.crdtlmtGrp
  ) {
    return {
      message: '情報を表示するには<u>こちら</u>',
      url: 'https://id.auone.jp/payment/limitrefer/credit.html'
    };
  };

  return {};
};

export const preProcessBalance = (balance: string, isKantanKessai: boolean, all_total_amt: string) => {
  if (isKantanKessai && !all_total_amt) return formatMoney('-');
  return formatMoney(balance);
};

export const trackingDisplayKantan = (balanceState:IBalanceState) => {
  const kantanAccounts = balanceState.kantan.accounts;
  const account = kantanAccounts && kantanAccounts.length > 0
    ? kantanAccounts[0]
    : {
      status: -1,
      kantan_info: intitialKantanInfo
    };
  const status = account.status;
  const kantanInfo = account.kantan_info || intitialKantanInfo;
  const userType = kantanInfo.user_type;
  let action = '';

  if (status === 0) {
    switch (userType) {
      case KANTAN_USER_TYPE.au_kantan_heavy:
        action = 'auかんたん決済AA_ヘビーユーザー'; break;
      case KANTAN_USER_TYPE.au_kantan_light:
        action = 'auかんたん決済AA_ライトユーザー'; break;
      case KANTAN_USER_TYPE.au_kantan_used:
        action = 'auかんたん決済AA_一時未利用者'; break;
      case KANTAN_USER_TYPE.au_kantan_none:
        action = 'auかんたん決済AA_完全未利用者'; break;
    }
  }

  if (status === 90) {
    action = 'auかんたん決済AA_合算請求不可ユーザー';
  }

  assetTopAnalyticsEvents.eventDisplayKantan(action);
};

export const getSelectorByAccType = (data_source: number, account_name: string) => {
  const accType = getAccountType(data_source);
  const { au_point_unyo, au_prepaid, au_credit, au_kantan_kessai, au_jbank, au_stock } = AccountType;
  const idElementAccs = [au_point_unyo, au_prepaid, au_credit, au_kantan_kessai, au_jbank, au_stock];

  const currentPath = typeof window !== 'undefined' ? getPathHasLastSlash(window.location.pathname) : '';
  const isTopAsset = [pathConstant.topPath].includes(currentPath);

  return idElementAccs.includes(accType) && isTopAsset ? `${accType}_${account_name}_msg` : '';
};

export const getBalanceAccount = (account: IAccount, maintenance_status?: number| undefined) => {
  const isError = account.is_error;
  const isAuMaintance = maintenance_status === MAINTENANCE_STATUS.SWALLOW_MAINTENANCE;
  const isBothMaintance = maintenance_status === MAINTENANCE_STATUS.BOTH_MAINTENANCE;
  const isMaintenance = isBothMaintance || isAuMaintance;

  let totalBalance;

  if (isMaintenance) {
    // super priority: maintaince account -> balance = 0
    totalBalance = 0;
  } if (isError) {
    // priority 1: render error account or au accounts is new, not linked yet
    totalBalance = 0;
  } else {
    // store acc is normal.
    totalBalance = +account.balance;
    if (getAccountType(account.data_source) === AccountType.au_credit) {
      totalBalance += +account.balance2;
    }
  }

  return totalBalance;
};

export const totalBalance = (accounts: Array<IAccount>, maintenance_status?: number| undefined) => {
  let summaryBalance = 0;

  accounts && accounts.forEach((account: IAccount) => {
    const totalBalance = getBalanceAccount(account, maintenance_status);

    summaryBalance += totalBalance;
  });

  return summaryBalance;
};

export const handleScrollTop = () => {
  const scrollChan = document.getElementById('next-container');

  if (scrollChan && scrollChan.scrollTo) {
    scrollChan.scrollTo({ top: 0, behavior: 'smooth' });
  } else {
    scrollChan && (scrollChan.scrollTop = 0);
  }
};

const { publicRuntimeConfig = {} } = getConfig() || {};
const { BUNDLE_CAMPAIGN_MESSAGE_LINKED, BUNDLE_CAMPAIGN_MESSAGE_UNLINKED } = publicRuntimeConfig;
export const campaignMsgForBundleLinked = BUNDLE_CAMPAIGN_MESSAGE_LINKED || '';
export const campaignMsgForBundleUnlinked = BUNDLE_CAMPAIGN_MESSAGE_UNLINKED || '';

export const getBundleMsg = (
  setUserCondition: IUserCondition,
  bundle: IBundleResponse,
  dataSource: number,
  account_status?: string
) => {
  const isOpenAccount = account_status === 'unlinked';
  const accType = getAccountType(dataSource);
  const isJbankUnLinked = AccountType.au_jbank === accType && isOpenAccount;
  const isAuPay = AccountType.au_prepaid === accType;
  const isAuPayCardUnLinked = AccountType.au_credit === accType && isOpenAccount;

  const {
    financialBundlePlanMember,
    payCardHoldSts,
    jibunBankAccountHoldSts,
    financeDisPaysetSts
  } = setUserCondition || initialUserCondition;
  const { message_settings, admin_settings } = bundle || initialMessageSetting;

  if (!admin_settings.show_financial_packages ||
    !message_settings.show_financial_packages
  ) {
    return;
  }

  let result: IBundleInfo | undefined = {};

  if (financialBundlePlanMember === FINANCIAL_BUNDLE.UNKNOWN) {
    return;
  } else if (financialBundlePlanMember === FINANCIAL_BUNDLE.NOT_APPLIED) {
    result = getBundleNotAppliedMsg(isAuPay);
  } else if (financialBundlePlanMember === FINANCIAL_BUNDLE.APPLIED) {
    result = getBundleAppliedMsg(
      payCardHoldSts,
      jibunBankAccountHoldSts,
      financeDisPaysetSts,
      isAuPay
    );
  } else if (financialBundlePlanMember === FINANCIAL_BUNDLE.APPLIED_CASE_2) {
    result = getFinancialPlanMemberCaseTwoMsg(
      payCardHoldSts,
      jibunBankAccountHoldSts,
      financeDisPaysetSts,
      isAuPay,
      isAuPayCardUnLinked,
      isJbankUnLinked
    );
  } else if (financialBundlePlanMember === FINANCIAL_BUNDLE.APPLIED_CASE_3) {
    result = getFinancialPlanMemberCaseThreeMsg(
      payCardHoldSts,
      jibunBankAccountHoldSts,
      financeDisPaysetSts,
      isAuPay,
      isAuPayCardUnLinked,
      isJbankUnLinked
    );
  }

  return result;
};

// Start: Financial Bundle Plan Member = 1

export const getBundleAppliedMsg = (
  payCardHoldSts: number,
  jibunBankAccountHoldSts: number,
  financeDisPaysetSts: number,
  isAuPay: boolean
) => {
  let result;

  if (payCardHoldSts === FINANCIAL_BUNDLE.UNKNOWN && isAuPay) {
    result = {
      textOrange: BUNDLE_NOTICE_TEXT.AUPAY_LINKED,
      bundleStatus: BUNDLE_CONTACT_MSG.UNKNOWN,
      element: BUNDLE_ELEMENT.LINKED,
      action: BUNDLE_GA_MSG.AUPAY_UNKOWN,
      url: campaignMsgForBundleLinked
    };
  } else if (payCardHoldSts === FINANCIAL_BUNDLE.APPLIED) {
    result = getPayCardAppliedMsg(
      jibunBankAccountHoldSts,
      financeDisPaysetSts,
      isAuPay
    );
  } else if (payCardHoldSts === FINANCIAL_BUNDLE.NOT_APPLIED) {
    result = getPayCardNotAppliedMsg(jibunBankAccountHoldSts, isAuPay);
  }

  return result;
};

export const getPayCardAppliedMsg = (
  jibunBankAccountHoldSts: number,
  financeDisPaysetSts: number,
  isAuPay: boolean
) => {
  let result;
  if (jibunBankAccountHoldSts === FINANCIAL_BUNDLE.UNKNOWN && isAuPay) {
    result = {
      textOrange: BUNDLE_NOTICE_TEXT.AUPAY_LINKED,
      bundleStatus: BUNDLE_CONTACT_MSG.UNKNOWN,
      element: BUNDLE_ELEMENT.LINKED,
      action: BUNDLE_GA_MSG.AUPAY_UNKOWN,
      url: campaignMsgForBundleLinked
    };
  } else if (jibunBankAccountHoldSts === FINANCIAL_BUNDLE.NOT_APPLIED) {
    result = getJibunNotAppliedMsg(isAuPay);
  } else if (jibunBankAccountHoldSts === FINANCIAL_BUNDLE.APPLIED) {
    result = getAuStockAppliedMsg(financeDisPaysetSts, isAuPay);
  }

  return result;
};

export const getPayCardNotAppliedMsg = (
  jibunBankAccountHoldSts: number,
  isAuPay: boolean
) => {
  let result;

  if (jibunBankAccountHoldSts === FINANCIAL_BUNDLE.UNKNOWN && isAuPay) {
    result = {
      textOrange: BUNDLE_NOTICE_TEXT.AUPAY_LINKED,
      bundleStatus: BUNDLE_CONTACT_MSG.UNKNOWN,
      element: BUNDLE_ELEMENT.LINKED,
      action: BUNDLE_GA_MSG.AUPAY_UNKOWN,
      url: campaignMsgForBundleLinked
    };
  } else if (jibunBankAccountHoldSts === FINANCIAL_BUNDLE.APPLIED) {
    if (isAuPay) {
      result = {
        textOrange: BUNDLE_NOTICE_TEXT.AUPAY_LINKED,
        bundleStatus: BUNDLE_CONTACT_MSG.APPLIED,
        element: BUNDLE_ELEMENT.LINKED,
        action: BUNDLE_GA_MSG.AUPAY_APPLIED,
        url: campaignMsgForBundleLinked
      };
    }
  } else if (jibunBankAccountHoldSts === FINANCIAL_BUNDLE.NOT_APPLIED) {
    if (isAuPay) {
      result = {
        textOrange: BUNDLE_NOTICE_TEXT.AUPAY_LINKED,
        bundleStatus: BUNDLE_CONTACT_MSG.APPLIED,
        element: BUNDLE_ELEMENT.LINKED,
        action: BUNDLE_GA_MSG.AUPAY_APPLIED,
        url: campaignMsgForBundleLinked
      };
    }
  }

  return result;
};

export const getJibunNotAppliedMsg = (isAuPay :boolean) => {
  let result;

  if (isAuPay) {
    result = {
      textOrange: BUNDLE_NOTICE_TEXT.AUPAY_LINKED,
      bundleStatus: BUNDLE_CONTACT_MSG.APPLIED,
      element: BUNDLE_ELEMENT.LINKED,
      action: BUNDLE_GA_MSG.AUPAY_APPLIED,
      url: campaignMsgForBundleLinked
    };
  }
  return result;
};

export const getAuStockAppliedMsg = (financeDisPaysetSts: number, isAuPay: boolean) => {
  let result;

  if (isAuPay) {
    if (
      [
        FINANCIAL_BUNDLE.APPLIED,
        FINANCIAL_BUNDLE.APPLIED_CASE_2,
        FINANCIAL_BUNDLE.APPLIED_CASE_3,
        FINANCIAL_BUNDLE.APPLIED_CASE_4
      ].includes(financeDisPaysetSts)
    ) {
      result = {
        textOrange: BUNDLE_NOTICE_TEXT.AUPAY_LINKED,
        bundleStatus: BUNDLE_CONTACT_MSG.APPLIED,
        element: BUNDLE_ELEMENT.LINKED,
        action: BUNDLE_GA_MSG.AUPAY_APPLIED,
        url: campaignMsgForBundleLinked
      };
    } else if (financeDisPaysetSts === FINANCIAL_BUNDLE.NOT_APPLIED) {
      result = {
        textOrange: BUNDLE_NOTICE_TEXT.AUPAY_LINKED,
        bundleStatus: BUNDLE_CONTACT_MSG.APPLIED,
        element: BUNDLE_ELEMENT.LINKED,
        action: BUNDLE_GA_MSG.AUPAY_NOTAPPLIED,
        url: campaignMsgForBundleLinked
      };
    } else if (financeDisPaysetSts === FINANCIAL_BUNDLE.UNKNOWN) {
      result = {
        textOrange: BUNDLE_NOTICE_TEXT.AUPAY_LINKED,
        bundleStatus: BUNDLE_CONTACT_MSG.UNKNOWN,
        element: BUNDLE_ELEMENT.LINKED,
        action: BUNDLE_GA_MSG.AUPAY_UNKOWN,
        url: campaignMsgForBundleLinked
      };
    }
  }

  return result;
};

// End : Financial Bundle Plan Member = 1

// spacing validate
export const SPACE_VALIDATE_REGEX = /^\S+$/;

export const isNullOrUndefined = (value: number | string) => {
  return value === null || value === undefined;
};

export const isZero = (value: string) => {
  return /^(0|０)$/.test(value);
};

// check if value is empty string or "0"
export const shouldShowInvalid = (value: number | string) => {
  if (isNullOrUndefined(value)) {
    return false;
  }

  const stringValue = (typeof value === 'number') ? value.toString() : value;
  return isZero(stringValue.trim());
};

// concatenates multiple strings automatically if input has more than one, else returns the single string
export const getEnvLink = (...envVariables: string[]) => {
  let url = '';
  const { publicRuntimeConfig = {} } = getConfig() || {};

  for (const envVariable of envVariables) {
    url += publicRuntimeConfig[envVariable] || '';
  }

  return url;
};

export const adjustStockInfo = (
  screenWidth: number,
  stockSubName: string,
  stockName: string,
  auStockBalance: number
) => {
  let updatedSubName: string = stockSubName;
  let updatedName: string = stockName;
  let stylingPerWidth: string = 'stock-wrap';

  const balanceLength: number = auStockBalance ? auStockBalance.toString().length : 0;

  if (auStockBalance) {
    const applyStylingAndUpdateNames = (subLength: number, nameLength?: number) => {
      if (screenWidth < 400) stylingPerWidth = 'stock-inline';
      if (subLength >= 0) {
        updatedSubName = stockSubName.substring(0, subLength) + (subLength < 7 ? '...' : '');
      };
      if (nameLength !== undefined) updatedName = stockName.substring(0, nameLength);
    };

    if (balanceLength > 15) {
      stylingPerWidth = 'stock-wrap';
      if (screenWidth < 430) applyStylingAndUpdateNames(0, 7);
      if (screenWidth < 400) applyStylingAndUpdateNames(0, 5);
      if (screenWidth < 380) applyStylingAndUpdateNames(0, 4);
      if (screenWidth < 360) applyStylingAndUpdateNames(0, 2);
    } else if (balanceLength <= 15 && balanceLength > 14) {
      if (screenWidth <= 430) stylingPerWidth = 'stock-wrap';
      if (screenWidth < 400) applyStylingAndUpdateNames(6);
      if (screenWidth < 380) applyStylingAndUpdateNames(3);
      if (screenWidth < 360) applyStylingAndUpdateNames(0, 7);
      if (screenWidth < 330) applyStylingAndUpdateNames(0, 9);
    } else if (balanceLength <= 14 && balanceLength > 12) {
      if (screenWidth <= 430) stylingPerWidth = 'stock-wrap';
      if (screenWidth < 400) applyStylingAndUpdateNames(5);
      if (screenWidth < 360) applyStylingAndUpdateNames(0, 8);
      if (screenWidth < 330) updatedSubName = stockSubName.substring(0, 4) + '...';
    } else if (balanceLength <= 12 && balanceLength > 10) {
      if (screenWidth <= 430) stylingPerWidth = 'stock-wrap';
      if (screenWidth < 360) applyStylingAndUpdateNames(3, 8);
      if (screenWidth < 330) {
        updatedSubName = stockSubName.substring(0, 7);
        stylingPerWidth = 'stock-inline';
      }
    } else if (balanceLength <= 10) {
      stylingPerWidth = 'stock-wrap';
    }
  }

  return { updatedSubName, updatedName, stylingPerWidth };
};

export const formatDateBuild = (timestamp: string): string => {
  const date = new Date(timestamp);

  const year: number = date.getFullYear();
  const month: string = String(date.getMonth() + 1).padStart(2, '0');
  const day: string = String(date.getDate()).padStart(2, '0');
  const hours: string = String(date.getHours()).padStart(2, '0');
  const minutes: string = String(date.getMinutes()).padStart(2, '0');

  return `${year}/${month}/${day} ${hours}:${minutes}`;
};

export const redirectToAuMenuHistoryPage = () => {
  const { publicRuntimeConfig = {} } = getConfig() || {};
  const redirectedUrl: string = publicRuntimeConfig.REDIRECT_AUPAY_HISTORY_URL;

  redirectExternalUrl(redirectedUrl);
};

export const getAuCreditType = (crd_sbt: string) => {
  const TYPE_ONE = ['01', '03', '04'];
  const TYPE_TWO = ['05', '06'];
  const NOT_OWN = '07';

  if (TYPE_ONE.includes(crd_sbt)) {
    return 'typeOne';
  }
  if (TYPE_TWO.includes(crd_sbt)) {
    return 'typeTwo';
  }
  if (NOT_OWN === crd_sbt) {
    return 'notOwn';
  }
  return '';
};
