import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ModalConfig, connectModal, InjectedProps, hide } from 'redux-modal';
import ReactSwipe from 'react-swipe';

import { MODAL_NAME } from '@src/utils/appContanst';
import { withLinkAccount } from '@src/hocs/withLinkAccount';
import { TutorialAnalyticsEvents } from '@src/analyticsEvents/tutorialAnalyticsEvents';

interface ModalTutorialProps extends InjectedProps {
  closeModal(): void;
  handleCloseModalTutorial(): void;
}

interface ModalTutorialStates {
  slideActive: number;
}

class ModalTutorial extends React.Component<
  ModalTutorialProps,
  ModalTutorialStates
> {
  reactSwipeEl: ReactSwipe | undefined;
  constructor(props: ModalTutorialProps) {
    super(props);

    this.state = {
      slideActive: 0
    };
  }

  componentDidUpdate(_:ModalTutorialProps, prevState:ModalTutorialStates) {
    if (prevState.slideActive != this.state.slideActive) {
      const TRACKING_TEXT_MAPPING: Record<number, string> = {
        0: 'チュートリアル1',
        1: 'チュートリアル2',
        2: 'チュートリアル3',
        3: 'チュートリアル4'
      };
      TutorialAnalyticsEvents.eventDisplayTutorial(
        TRACKING_TEXT_MAPPING[this.state.slideActive]
      );
    }
  }

  handleNextButton = (index: number) => {
    if (index < 3) {
      if (this.reactSwipeEl) {
        this.reactSwipeEl.next();
      }
    } else {
      this.handleCloseModal();
    }
  }

  onSwipeChange = (index: number, _elem: HTMLElement) => {
    this.setState({ slideActive: index });
  }

  handleClickOnCircle = (index: number) => {
    if (this.reactSwipeEl) {
      this.reactSwipeEl.slide(index, 100);
    }
  }

  handleCloseModal = () => {
    if (localStorage) {
      localStorage.setItem('TUTORIAL_READ', 'true');
      this.props.closeModal();
    }
  }

  render() {
    const { slideActive } = this.state;
    const arr = [...Array(4)].map((_, i) => i + 1);

    const TEXT = [
      {
        title: '金融の体験をサポートします！',
        description:
          '私たちは、単なるツールではなく、信頼できるパートナーとして、お客様の日々の収支管理を共に進めてまいります。'
      },
      {
        title: 'あなたにあったサービスに出会える！',
        description:
          'お金の管理を活用すれば、簡単にご自身のお金の状況がわかり、理解を深めながら、お得なサービス情報と出会えます。'
      },
      {
        title: 'あなたのお金の情報をまとめて確認！',
        description:
          'au PAY 残高やポイントの管理、au PAY カードのご利用額、引落日、引落額、さらにauじぶん銀行や三菱UFJ eスマート証券の残高なども簡単に確認できます。'
      }
    ] as const;

    return (
      <div className={'modal kpfm-modal modal-tutorial is-demo'}>
        <div className={
          `modal-content modal-tutorial-content ${slideActive === 3 ? 'last-step' : ''}`
        }>
          {slideActive === 3 && (
            <p className='big-text'>
              お金の管理で<br/>
              お金を<span>{'"'}見える化{'"'}</span>しませんか？
            </p>
          )}

          <ReactSwipe
            className="carousel"
            swipeOptions={{
              continuous: false,
              startSlide: slideActive,
              speed: 100,
              transitionEnd: this.onSwipeChange
            }}
            ref={(el: ReactSwipe) => (this.reactSwipeEl = el)}
          >
            {arr.map((_, index) => (
              <img
                key={index}
                src={`/static/media/imageTutorial${index + 1}.jpg`}
                className={`image-tutorial ${slideActive === index ? 'active' : ''}`}
                alt={`tutorial-${index + 1}`}
              />
            ))}
          </ReactSwipe>

          {/* swipe dot */}
          <div className='swipe-pagination'>
            {arr.map((_, index) => (
              <div
                key={index}
                onClick={() => this.handleClickOnCircle(index)}
                className={`circle ${slideActive === index ? 'active' : ''}`}
              />
            ))}
          </div>

          {/* Text content */}
          <div className="modal-bottom-content">
            {slideActive !== 3 && (
              <>
                <h1>{TEXT[slideActive] ? TEXT[slideActive].title : ''}</h1>
                <p>{TEXT[slideActive] ? TEXT[slideActive].description : ''}</p>
              </>
            )}

            <button
              className={`button-next ${slideActive === 3 ? 'last-step' : ''}`}
              onClick={() => this.handleNextButton(slideActive)}
            >
              {slideActive === 3 ? 'さあ、はじめよう' : '次へ'}
            </button>
            {slideActive !== 3 && (
              <button
                className='button-skip'
                onClick={this.handleCloseModal}
              >
                スキップ
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const modalConfig: ModalConfig = { name: MODAL_NAME.TUTORIAL };
const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeModal: () => dispatch(hide(MODAL_NAME.TUTORIAL))
});

export default connectModal(modalConfig)(
  connect(null, mapDispatchToProps)(withLinkAccount(ModalTutorial))
);
