import { analyticsLogEvent } from '@src/utils/analytics';

export const TutorialAnalyticsEvents = {
  eventClickTutorial1: () =>
    analyticsLogEvent({
      category: '【click】1-1チュートリアル（2019/7)_まとめる',
      action: '使ってみる'
    }),
  eventClickTutorial2: () =>
    analyticsLogEvent({
      category: '【click】1-2チュートリアル（2019/7)_一覧管理',
      action: '使ってみる'
    }),
  eventDisplayTutorial: (
    action: string
  ) => analyticsLogEvent({ category: '【visible】チュートリアル', action })
};
